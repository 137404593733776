"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
require("../css/app.scss");
require("flowbite");
var flowbite_1 = require("flowbite");
var vanilla_cookieconsent_1 = require("vanilla-cookieconsent");
window.addEventListener('load', function () {
    var _this = this;
    (0, vanilla_cookieconsent_1.run)({
        onConsent: function () {
            localStorage.setItem('cookieconsent', 'yes');
        },
        onChange: function (u) {
            localStorage.setItem('cookieconsent', 'yes');
        },
        categories: {
            necessary: {
                enabled: true,
                readOnly: true // this category cannot be disabled
            },
            analytics: {
                enabled: false,
                readOnly: false,
                // Delete specific cookies when the user opts-out of this category
                autoClear: {
                    cookies: [
                        {
                            name: /^_ga/, // regex: match all cookies starting with '_ga'
                        },
                        {
                            name: '_gid', // string: exact cookie name
                        }
                    ]
                }
            }
        },
        language: {
            default: 'en',
            translations: {
                en: {
                    consentModal: {
                        title: 'We use cookies',
                        description: 'Our website uses cookies to enhance your browsing experience and provide personalized services. By accepting cookies, you agree to our use of cookies as described in our Cookie Policy.',
                        acceptAllBtn: 'Accept all',
                        acceptNecessaryBtn: 'Reject all',
                        showPreferencesBtn: 'Manage Individual preferences'
                    },
                    preferencesModal: {
                        title: 'Manage cookie preferences',
                        acceptAllBtn: 'Accept all',
                        acceptNecessaryBtn: 'Reject all',
                        savePreferencesBtn: 'Accept current selection',
                        closeIconLabel: 'Close modal',
                        sections: [
                            {
                                title: 'Strictly Necessary cookies',
                                description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                                //this field will generate a toggle linked to the 'necessary' category
                                linkedCategory: 'necessary'
                            },
                            {
                                title: 'Performance and Analytics',
                                description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                                linkedCategory: 'analytics'
                            },
                            {
                                title: 'More information',
                                description: 'For any queries in relation to my policy on cookies and your choices, please <a href="/en/contact">contact us</a>'
                            }
                        ]
                    }
                },
                fr: {
                    consentModal: {
                        title: "Nous utilisons des cookies",
                        description: "Notre site web utilise des cookies pour améliorer votre expérience de navigation et fournir des services personnalisés. En acceptant les cookies, vous acceptez notre utilisation des cookies telle que décrite dans notre Politique en matière de cookies.",
                        acceptAllBtn: "Accepter tout",
                        acceptNecessaryBtn: "Rejeter tout",
                        showPreferencesBtn: "Gérer les préférences individuelles"
                    },
                    preferencesModal: {
                        title: "Gérer les préférences de cookies",
                        acceptAllBtn: "Accepter tout",
                        acceptNecessaryBtn: "Rejeter tout",
                        savePreferencesBtn: "Accepter la sélection actuelle",
                        closeIconLabel: "Fermer le formulaire",
                        sections: [
                            {
                                title: "Cookies strictement nécessaires",
                                description: "Ces cookies sont essentiels au bon fonctionnement du site Web et ne peuvent pas être désactivés.",
                                linkedCategory: 'necessary'
                            },
                            {
                                title: "Performances et Analyse",
                                description: "Ces cookies collectent des informations sur votre utilisation de notre site Web. Toutes les données sont anonymisées et ne peuvent pas être utilisées pour vous identifier.",
                                linkedCategory: 'analytics'
                            },
                            {
                                title: "Plus d'informations",
                                description: "Pour toute question concernant ma politique en matière de cookies et vos choix, veuillez <a href=\"/fr/contactez-nous\">nous contacter</a>."
                            }
                        ]
                    }
                },
            }
        }
    }).then(function (value) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, vanilla_cookieconsent_1.setLanguage)(currentLang, true)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); });
    if (localStorage.getItem('color-theme') === 'dark') {
        document.documentElement.classList.add('dark');
        document.cookie = "color-theme=dark;path=/";
    }
    else {
        document.documentElement.classList.remove('dark');
        document.cookie = "color-theme=light;path=/";
    }
    var darkModeToggleButton = document.querySelector('#dark-mode-toggle');
    if (darkModeToggleButton) {
        darkModeToggleButton.addEventListener('click', function () {
            if (localStorage.getItem('color-theme') === 'dark') {
                document.documentElement.classList.remove('dark');
                document.documentElement.classList.remove('cc--darkmode');
                localStorage.setItem('color-theme', 'light');
                // TODO manage cookies
                document.cookie = "color-theme=light;path=/";
            }
            else {
                document.documentElement.classList.add('dark');
                document.documentElement.classList.add('cc--darkmode');
                localStorage.setItem('color-theme', 'dark');
                document.cookie = "color-theme=dark;path=/";
            }
        });
    }
    (0, flowbite_1.initFlowbite)();
    var topMenu = document.querySelector('#top-menu');
    var topMenuToggleButton = document.querySelector('#toggle-top-menu');
    var options = {
        onCollapse: function () {
            console.log('element has been collapsed');
        },
        onExpand: function () {
            console.log('element has been expanded');
        },
        onToggle: function () {
            console.log('element has been toggled');
        }
    };
    var collapse = new flowbite_1.Collapse(topMenu, topMenuToggleButton, options);
});
